<template>
    <div id="app">
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"
        @ready="onEditorReady"
        @focus="onEditorFocus"
        @blur="onEditorBlur"
        @input="onEditorInput"
        @destroy="onEditorDestroy"></ckeditor>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
import ImageResizeButtons from '@ckeditor/ckeditor5-image/src/imageresize/imageresizebuttons';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import List from '@ckeditor/ckeditor5-list/src/list';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Undo from '@ckeditor/ckeditor5-undo/src/undo';
import IndentCodeBlockCommand from '@ckeditor/ckeditor5-code-block/src/indentcodeblockcommand';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Table from '@ckeditor/ckeditor5-table/src/table';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
    export default {
        name: 'CustomCkEditor',
        props: ['ckValue', 'ckPlaceholder'],
        data() {
            return {
                editorData: "",
                editor: ClassicEditor,
                    editorConfig: {
                        allowedContent: 'p b i ul li; img[!src]',
                        plugins: [
                            List,
                            Base64UploadAdapter,
                            Essentials,
                            Autoformat,
                            Strikethrough,
                            Underline,
                            Bold,
                            Italic,
                            Heading,
                            Image,
                            ImageCaption,
                            ImageResizeEditing,
                            ImageResizeButtons,
                            ImageResizeHandles,
                            ImageStyle,
                            ImageToolbar,
                            ImageUpload ,
                            Link,
                            Paragraph,
                            Subscript,
                            Superscript,
                            Font,
                            Undo,
                            IndentCodeBlockCommand,
                            Highlight,
                            RemoveFormat,
                            TodoList,
                            Indent,
                            BlockQuote,
                            Table,
                            MediaEmbed,
                            CodeBlock,
                            SpecialCharacters, 
                            SpecialCharactersEssentials,
                            PageBreak,
                            LinkImage
                        ],
                        fontSize: {
                            options: [ 10, 12, 14, 'default', 18, 20, 22 ],
                            supportAllValues: true
                        },
                        mediaEmbed: {
                            previewsInData: true,
                            providers: [
                                {
                                    name: 'youtube',
                                    url: [
                                        /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                                        /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                                        /^youtube\.com\/embed\/([\w-]+)/,
                                        /^youtu\.be\/([\w-]+)/
                                    ],
                                    html: match => {
                                        const id = match[ 1 ];

                                        return (
                                            '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
                                            `<iframe src="https://www.youtube.com/embed/${ id }" ` +
                                            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                                            'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                                            '</iframe>' +
                                            '</div>'
                                        );
                                    }
                                },
                            ]
                        },
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                            ]
                        },
                        placeholder: this.ckPlaceholder,
                        fontFamily: {
                            options: [
                                'default',
                                'Arial, Helvetica, sans-serif',
                                'Courier New, Courier, monospace',
                                'Georgia, serif',
                                'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                'Tahoma, Geneva, sans-serif',
                                'Times New Roman, Times, serif',
                                'Trebuchet MS, Helvetica, sans-serif',
                                'Verdana, Geneva, sans-serif'
                            ],
                            supportAllValues: true
                        },
                        toolbar: {
                            items: [
                                'selectAll', '|',
                                'heading', '|',
                                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', 'removeFormat', '|',
                                'bulletedList', 'numberedList', 'todoList', '|',
                                'outdent', 'indent', '|',
                                'undo', 'redo',
                                '-',
                                'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
                                'link', 'uploadImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock', '|',
                                'specialCharacters', 'pageBreak',
                            ],
                            shouldNotGroupWhenFull: true
                        },
                        image: {
                            resizeOptions: [
                                {
                                    name: 'resizeImage:original',
                                    value: null,
                                    icon: 'original'
                                },
                                {
                                    name: 'resizeImage:50',
                                    value: '50',
                                    icon: 'medium'
                                },
                                {
                                    name: 'resizeImage:75',
                                    value: '75',
                                    icon: 'large'
                                }
                            ],
                            toolbar: [
                                'imageStyle:block',
                                'imageStyle:side',
                                '|',
                                'toggleImageCaption',
                                'imageTextAlternative',
                                '|',
                                'linkImage'
                            ]
                        },
                        table: {
                            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                        },
                        language: 'en'
                    }
            };
        },
        created(){
            this.editorData = this.ckValue
        },
        methods:{
            onEditorReady(){
                this.$emit('ck-on-editor-ready');
            },
            onEditorFocus(){
                this.$emit('ck-on-editor-focus');
            },
            onEditorBlur(){
                this.$emit('ck-on-editor-blur');
            },
            onEditorInput(editor){
                this.$emit('ck-on-editor-input', editor);
            },
            onEditorDestroy(){
                this.$emit('ck-on-editor-destroy');
            },
        }
    };
</script>